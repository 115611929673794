import './scripts/toast.js'
import './bootstrap';

// import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
// import '@fortawesome/fontawesome-free/scss/brands.scss';
// import '@fortawesome/fontawesome-free/scss/regular.scss';
// import '@fortawesome/fontawesome-free/scss/solid.scss';
// import '@fortawesome/fontawesome-free/scss/v4-shims.scss';

let deleteModal = document.getElementById('modal-confirm-delete');
if (deleteModal) {
    deleteModal.addEventListener('show.bs.modal', function (event) {
        let deleteUrl = event.relatedTarget.getAttribute('data-delete-url');

        let message = event.relatedTarget.getAttribute('data-message');
        if (message == '' || message == 'undefined' || message == null) {
            message = deleteModal.querySelector('.modal-body-default').innerHTML;
        }

        let deleteForm = deleteModal.querySelector('.delete-form');
        let body = deleteModal.querySelector('.modal-body');

        deleteForm.action = deleteUrl;
        body.innerHTML = message;
    });
}

let confirmModal = document.getElementById('modal-confirm-link');
if (confirmModal) {
    confirmModal.addEventListener('show.bs.modal', function (event) {
        let linkUrl = event.relatedTarget.getAttribute('data-link-url');

        let message = event.relatedTarget.getAttribute('data-message');
        if (message == '' || message == 'undefined' || message == null) {
            message = confirmModal.querySelector('.modal-body-default').innerHTML;
        }

        let link = confirmModal.querySelector('.modal-confirm-link');
        let body = confirmModal.querySelector('.modal-body');

        link.href = linkUrl;
        body.innerHTML = message;
    });
}

let dataFormSubmitId = document.querySelectorAll('[data-form-submit-id]')
dataFormSubmitId.forEach(function (e) {
    e.addEventListener('click', function () {
        document.getElementById(this.dataset.formSubmitId).click()
    })
})

import.meta.glob([
    '../images/**',
]);
